import React from "react"
import "../delete_account.css"
import BreadcrumbRevokeMember from "../breadcrumb/breadcrumb"
const Step4 = () => {
  return (
    <div className="container">
      <BreadcrumbRevokeMember paths={["TOP", "企業情報", "退会手続き完了"]} />
      <div className="bodyContent" style={{ height: "244px" }}>
        <div style={{ textAlign: "center", paddingTop: "50px" }}>
          <div className="headText">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  display: "flex",
                  marginRight: "7px",
                }}
              >
                <svg
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="14.5"
                    cy="14"
                    r="13"
                    fill="white"
                    stroke="#EA6D69"
                    strokeWidth="2"
                  />
                  <path
                    d="M6.80078 14.0001L11.7008 18.9001L21.5008 9.1001"
                    stroke="#EA6D69"
                    strokeWidth="3"
                  />
                </svg>
              </span>
              <span>退会手続きが完了しました。</span>
            </div>
          </div>
        </div>

        <div className="bodyText">
          <div>この度は、サービス名をご利用頂きありがとうございました。</div>
          <div>またのご利用をお待ちしております。</div>
        </div>
      </div>
    </div>
  )
}

export default Step4
